import React, { useEffect, useState } from "react";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import BASE_URL from "../service/ProductionService";

const drawerWidth = 240;

const Buttons = ({ getData }) => {
  const [btnClass, setBtnClass] = useState(false);
  const [btnName, setBtnName] = useState("");
  const [buttonData, setButtonData] = useState([]);
  const [navData, setNavData] = useState([]);

  console.log(getData,"buttonData")

  const [mobileOpen, setMobileOpen] = React.useState(false);
  useEffect(() => {
    getButtonData();
  }, []);

  const handleOnClick = (id, name) => {
    setBtnClass(true);
    setBtnName(name);
    getData(id);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Select a Year
      </Typography>
      <Divider />
      <List
        className={
          btnClass && btnName === "2011-2014"
            ? "onclick"
            : "btn ml-2 ml-md-3 year-nav"
        }
        onClick={() => handleOnClick("0", "2011-2014")}
      >
        2011-2014
      </List>
      {navData.map((each) => (
        <List
          key={each.id}
          // className="btn ml-2 ml-md-3 year-btn"
          className={
            btnClass && btnName === each.year
              ? "onclick"
              : "btn ml-2 ml-md-3 year-nav"
          }
          onClick={() => handleOnClick(each.id, each.year)}
        >
          {each.year}
        </List>
      ))}
    </Box>
  );

  const getButtonData = async () => {
    console.log("base url called", BASE_URL);
    await axios(BASE_URL + `years`, { method: "GET" }).then(function (
      response
    ) {
      setButtonData(response.data.result);
      setNavData(response.data.result);
    });
  };

  return (
    <>
      <div className="heading" id="heading">
        <div className="d-flex justify-content-center">
          <div className="buttons">
            <button
              className={
                btnClass && btnName === "2011-2014"
                  ? "onclick"
                  : "btn ml-2 ml-md-3 year-btn"
              }
              onClick={() => handleOnClick("0", "2011-2014")}
            >
              2011-2014
            </button>
            {buttonData.map((each) => (
              <button
                key={each.id}
                // className="btn ml-2 ml-md-3 year-btn"
                className={
                  btnClass && btnName === each.year
                    ? "onclick"
                    : "btn ml-2 ml-md-3 year-btn"
                }
                onClick={() => handleOnClick(each.id, each.year)}
              >
                {each.year}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div style={{ position: "absolute" }}>
        <Box sx={{ display: "flex" }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon style={{ color: "white" }} />
            </IconButton>
          </Toolbar>

          <Box component="nav">
            <Drawer
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </Box>
          <Box component="main" sx={{ p: 3 }}></Box>
        </Box>
      </div>
    </>
  );
};

export default Buttons;
