import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./style.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Background4 from "../assets/backgroundImages/background4.png";

const True_Champion = (props) => {
  const renderTooltip = (name, award) => (
    <Tooltip id="button-tooltip" {...props}>
      <div
        className="container trueChampion-1"
        // style={{
        //   display: "flex",
        //   justifyContent: "flex-start",
        //   flexDirection: "column",
        //   fontSize : "17px"
        // }}
      >
        {award}
        <div
          className="trueChampion-2"
          // style={{
          //   fontFamily: "HelveticaNeue",
          //   fontSize: "14px",
          //   color: "#139ded",
          //   paddingTop: "15px",

          // }}
        >
          {name}
        </div>
        <hr
          className="trueChampion-3"
          //style={{ color: "blue", width: "100%", marginTop: "5px ", marginBottom : "5px" }}
        />
      </div>
    </Tooltip>
  );

  const sectionStyle = {
    // height: "100vh",
    // width: "100vw",
    // display: "Flex",
    // justifyContent: "center",
    // alignItems: "center",
    // backgroundSize: "cover",
    // // backgroundImage : `url(${Background1})`
    // font: "white",
  };
  return (
    <>
      {props?.trueChampions?.length > 0 && (
        <Box
          className="trueChampion-4"
          style={{ backgroundImage: `url(${Background4})` }}
        >
          <Grid
            className="trueChampion-5"
            container
            spacing={3}
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "space-around",
            //   flexDirection: "row",
            //   margin: "10px",
            //   textAlign: "center",
            //   border: "10px",
            // }}
          >
            <>
              <Grid item xs="12" className="navigators trueChampionName">
                True Champion
                {/* <div className="navigators trueChampionName">True Champion Awards</div> */}
              </Grid>
              {props.trueChampions.map((each) => {
                const images = each.image_urls;
                return (
                  <Grid item xs="auto" className="trueChampion-6">
                    <div className="trueChampion-7">
                      {images.map((eachImg) => {
                        return (
                          <OverlayTrigger
                            className="placementTrigger"
                            placement={props.width > "800" ? "right" : "bottom"}
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip(each.name, each.award_name)}
                            //style={{ backgroundColor: "#fff" }}
                          >
                            <img
                              src={require(`../${eachImg}`)}
                              className="c-class-margin c-class-image trueChampion-8"
                              alt=""
                              //style={{ height: "200px" }}
                            />
                          </OverlayTrigger>
                        );
                      })}
                    </div>
                    {/* <h1
                      style={{
                        fontFamily: "Rochester",
                        color: "white",
                        fontSize: "30px",
                      }}
                    >
                      {each.award_name}
                    </h1> */}
                  </Grid>
                );
              })}
            </>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default True_Champion;
