import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./style.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import BackgroundMPV from "../assets/backgroundImages/background3.png";

const MostValuabe_Player = (props) => {
  const renderTooltip = (name, award) => (

    <Tooltip id="button-tooltip" {...props}>
      <div
        className="container mostValuabe-1"
        // style={{
        //   display: "flex",
        //   justifyContent: "flex-start",
        //   flexDirection: "column",
        //   fontSize : "17px"
        // }}
      >
        {award}
        <div
          className="mostValuabe-2"
          // style={{
          //   fontFamily: "HelveticaNeue",
          //   fontSize: "14px",
          //   color: "#139ded",
          //   paddingTop: "15px",

          // }}
        >
          {name}
        </div>
        <hr
          className="mostValuabe-3"
          // style={{ color: "blue", width: "100%", marginTop: "5px ", marginBottom : "5px" }}
        />
      </div>
    </Tooltip>
  );
  // const sectionStyle = {
  //   height: "100vh",
  //   width: "100vw",
  //   display: "Flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   backgroundSize: "cover",
  //   // backgroundImage : `url(${Background1})`
  //   font: "white",
  // };
  return (
    <>
      {props?.MostValuabePlayers?.length > 0 && (
        <>
          <Box
            className="mostValuabe-4"
            style={{
              backgroundImage: `url(${BackgroundMPV})`,
            }}
          >
            <Grid
              className="mostValuabe-5"
              container
              spacing={3}
              // style={{
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "space-around",
              //   flexDirection: "row",
              //   margin: "10px",
              //   textAlign: "center",
              //   border: "10px",
              // }}
            >
              <>
                <Grid item xs="12" className="navigators mostValuabeName">
                  Most Valuable Player
                  {/* <div className="navigators mostValuabeName">Most Valuabe Players</div> */}
                </Grid>
                {props.MostValuabePlayers.map((each) => {
                
                const images = each.image_urls;
                
                  return (
                    <Grid
                      item
                      xs="auto"
                      className="mostValuabe-6"
                      // style = {{ height : "175px"}}
                    >
                      <div className="mostValuabe-7">
                        {images.map((eachImg) => {
                         
                          return (
                            <OverlayTrigger
                              className="placementTrigger"
                              placement={
                                props.width > "800" ? "right" : "bottom"
                              }
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(
                                each.name,
                                each.award_name
                              )}
                              style={{ backgroundColor: "#fff" }}
                            >
                              <img
                                src={require(`../${eachImg}`)}
                                className="c-class-margin c-class-image mostValuabe-8"
                                alt=""
                                //style={{ height: "200px" }}
                              />
                            </OverlayTrigger>
                          );
                        })}
                      </div>
                      {/* <h1
                        style={{
                          fontFamily: "Rochester",
                          color: "white",
                          fontSize: "30px",
                        }}
                      >
                        {each.award_name}
                      </h1> */}
                    </Grid>
                  );
                })}
              </>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};
export default MostValuabe_Player;
