import React from "react";
import Box from "@mui/material/Box";
import Background4 from "../assets/backgroundImages/background4.png";
import Grid from "@mui/material/Grid";
import "./style.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Sale_Awards = (props) => {
  const renderTooltip = (name, award) => (
    <Tooltip id="button-tooltip" {...props}>
      <div
        className="container salesAward-1"
        // style={{
        //   display: "flex",
        //   justifyContent: "flex-start",
        //   flexDirection: "column",
        //   fontSize : "17px"
        // }}
      >
        {award}
        <div
          className="salesAward-2"
          // style={{
          //   fontFamily: "HelveticaNeue",
          //   fontSize: "14px",
          //   color: "#139ded",
          //   paddingTop: "15px",

          // }}
        >
          {name}
        </div>
        <hr
          className="salesAward-3"
          // style={{ color: "blue", width: "100%", marginTop: "5px ", marginBottom : "5px" }}
        />
      </div>
    </Tooltip>
  );

  // const sectionStyle = {
  //   height: "100vh",
  //   width: "100vw",
  //   display: "Flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   backgroundSize: "cover",
  //   // backgroundImage : `url(${Background1})`
  //   font: "white",
  // };

  console.log(props?.salesAwards?.length,"salesawardlength")
  return (
    <>
      {props?.salesAwards?.length > 0 && (
        <>
          <Box
            className="salesAward-4"
            style={{
              backgroundImage: `url(${Background4})`,
              // paddingTop : "150px",
              // paddingRight : "100px",
              // paddingLeft : "100px"
            }}
          >
            <Grid
              className="salesAward-5"
              container
              spacing={3}
              // style={{
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "space-around",
              //   flexDirection: "row",
              //   margin: "10px",
              //   textAlign: "center",
              //   border: "10px",
              //   paddingBottom : "150px"
              // }}
            >
              <>
                <Grid item xs="12" className="navigators salesAwardName">
                  Sales Awards
                  {/* <div className="navigators salesAwardName">Sales Awards</div> */}
                </Grid>
                {props.salesAwards.map((each) => {
                  const images = each.image_urls;
                  return (
                    <Grid
                      item
                      xs="auto"
                      className="salesAward-6"
                      // style = {{ height : "175px",marginBottom:"12px"}}
                    >
                      <div className="salesAward-7">
                        {images.map((eachImg) => {
                          return (
                            <OverlayTrigger
                              className="placementTrigger"
                              placement={
                                props.width > "800" ? "right" : "bottom"
                              }
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(
                                each.name,
                                each.award_name
                              )}
                              //style={{ backgroundColor: "#fff" }}
                            >
                              <img
                                src={require(`../${eachImg}`)}
                                className="c-class-margin c-class-image salesAward-8"
                                alt=""
                                //style={{height : "100px" }}
                              />
                            </OverlayTrigger>
                          );
                        })}
                      </div>
                      <div>
                        <h1
                          className="salesAward-9"
                          // style={{
                          //   fontFamily: "Rochester",
                          //   color: "white",
                          //   fontSize: "21px",
                          //   width : "220px",
                          //   paddingTop : "22px",
                          //   margin : "auto"
                          // }}

                          style={{
                            paddingTop: "24px",
                          }}
                        >
                          {each.award_name}
                        </h1>
                      </div>
                    </Grid>
                  );
                })}
              </>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default Sale_Awards;
