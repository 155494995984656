import { useEffect, useState } from "react";

function useWindowSize() {
  const [size, setSize] = useState([ window.innerWidth]);

  useEffect(() => {
    const handleResize = () => {
       setSize([ window.innerWidth])
    } 
    window.addEventListener("resize", handleResize)
  }, [])
  return size;
}

function WindowSize(){
  const [ width] = useWindowSize();
  
  return(<>
  </>
  )
}

export default WindowSize
