// import logo from './logo.svg';
import React from "react";
import "./App.css";

import Router from "./components/Router";
import WindowSize from "./components/Windowsize";

function App() {
  // const [height, width] = useWindowSize();
  // console.log(height,width,'log in App.js')
  return(
  
  <div style={{width : "100%"  , overflow : "hidden", overflowY : "hidden"}}>
   {WindowSize()}
   <Router />
  </div>
 
    
  )
}

export default App;
