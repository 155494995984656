import React, { useState } from "react";
import Buttons from "./Buttons";
import FullpageScroll from "./fullPageScroll";
import axios from "axios";
import Home from "./Home";
import Aawrds from "./Awards11-14";
import Background1 from "../assets/backgroundImages/Background1.jpg";
import BASE_URL from "../service/ProductionService";
import DEV_BASE_URL from "../service/DevlopmentService";

function Router() {
  const [btnId, setBtnId] = useState("");
  const [choice, setChoice] = useState([]);
  const [coreValueAwards, setCoreValueAwards] = useState([]);
  const [individualAwards, setIndividualawards] = useState([]);
  const [JuryAwards, setJuryAwards] = useState([]);
  const [ManagedServices, setManagedServices] = useState([]);
  const [MostValuabePlayer, setMostValuabePlayer] = useState([]);
  const [projectAwardStarPerformers, setProjectAwardStarPerformers] = useState(
    []
  );
  const [projectAwardStarFixedPerformers, setProjectAwardStarFixedPerformers] =
    useState([]);
  const [
    projectAwardStarStratagicPerformer,
    setprojectAwardStarStratagicPerformer,
  ] = useState([]);
  const [salesAward, setSalesAward] = useState([]);
  const [sharedServices, setSharedServices] = useState([]);
  const [tmSupportProjectsStarPerformer, setTmSupportProjectsStarPerformer] =
    useState([]);
  const [trueChampion, setTrueChampion] = useState([]);
  const [projectAwardsStaffAugTMStarPerformers, setProjectAwardsStaffAugTMStarPerformers] = useState([])
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true)

  const getFormData = async (id) => {
    setLoading(true)
    setBtnId(id);
    setResult([]);
    setChoice([]);
    setCoreValueAwards([]);
    setIndividualawards([]);
    setJuryAwards([]);
    setManagedServices([]);
    setMostValuabePlayer([]);
    setProjectAwardStarPerformers(
      []
    );
    setProjectAwardStarFixedPerformers(
      []
    );
    setProjectAwardsStaffAugTMStarPerformers([]);
    setprojectAwardStarStratagicPerformer(
      []
    );
    setSalesAward([]);
    setSharedServices([]);
    setTmSupportProjectsStarPerformer(
      []
    );
    setTrueChampion([]);
    if (id >= 1) {
      await axios(BASE_URL + `getyeardata?year=${id}`, {
        method: "GET",
      })
        .then(function (response) {
          console.log(response, "respnce of the data")
          setLoading(false)
          setResult(response.data.result);
          setChoice(response.data.result[0].Choice);
          setCoreValueAwards(response.data.result[1].Core_Value_awards);
          setManagedServices(response.data.result[4].Managed_Services);
          setIndividualawards(response.data.result[2].Individual_awards);
          setJuryAwards(response.data.result[3].Jury_awards);
          setMostValuabePlayer(response.data.result[5].Most_valuable_player);
          setProjectAwardStarPerformers(
            response.data.result[8].Project_Awards_Star_Performers
          );
          setProjectAwardsStaffAugTMStarPerformers(response.data.result[7].Project_Awards_Staff_Aug_TM_Star_Performers)
          setProjectAwardStarFixedPerformers(
            response.data.result[6].Project_Awards_Fixed_Star_Performers
          );
          setprojectAwardStarStratagicPerformer(
            response.data.result[9].Project_Awards_Strategic_Star_Performers
          );
          setSalesAward(response.data.result[10].Sales_awards);
          setSharedServices(response.data.result[11].Shared_Services);
          setTmSupportProjectsStarPerformer(
            response.data.result[12].TMSupport_Projects_Star_Performers
          );
          setTrueChampion(response.data.result[13].True_Champion);
        })
        .catch(function (e) {
          setLoading(true)
        })
    }
  }
  return (
    <div >
      <Buttons getData={getFormData} />
      {btnId === "" ? (
        <Home />
      ) : btnId === "0" ? (
        <Aawrds />
      ) : (
        <>
          {loading === true ? (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundImage: `url(${Background1})`, width: "100vw", height: "100vh", backgroundSize: "cover" }}>
              <p style={{ color: "white", fontSize: "45px" }}>Loading...</p>
            </div>
          ) : (
            <FullpageScroll
              results={result}
              choices={choice}
              coreValueAward={coreValueAwards}
              ManagedService={ManagedServices}
              individualAward={individualAwards}
              juryAward={JuryAwards}
              MostValuabePlayers={MostValuabePlayer}
              projectAwardStarPerformer={projectAwardStarPerformers}
              projectAwardStarFixedPerformer={projectAwardStarFixedPerformers}
              projectAwardsStaffAugTMStarPerformers={projectAwardsStaffAugTMStarPerformers}
              projectAwardStarStratagicPerformers={
                projectAwardStarStratagicPerformer
              }
              salesAwards={salesAward}
              sharedService={sharedServices}
              tmSupportProjectsStarPerformers={tmSupportProjectsStarPerformer}
              trueChampions={trueChampion}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Router;
