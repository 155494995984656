import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./style.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Background4 from "../assets/backgroundImages/background4.png";

const Shared_Services = (props) => {
  const renderTooltip = (name, award) => (
    <Tooltip id="button-tooltip" {...props}>
      <div
        className="container sharedServices-1"
        // style={{
        //   display: "flex",
        //   justifyContent: "flex-start",
        //   flexDirection: "column",
        //   fontSize:"15px"
        // }}
      >
        {award}
        <div
          className="sharedServices-2"
          // style={{
          //   fontFamily: "Poppins",
          //   fontSize: "14px",
          //   color: "#139ded",
          //   paddingTop: "6px",
          // }}
        >
          {name}
        </div>
        <hr
          className="sharedServices-3"
          // style={{ color: "blue", width: "100%", marginTop: "3px ",marginBottom:"10px" }}
        />
      </div>
    </Tooltip>
  );
  // const sectionStyle = {
  //   height: "100vh",
  //   width: "100vw",
  //   display: "Flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   backgroundSize: "cover",
  //   // backgroundImage : `url(${Background1})`
  //   font: "white",
  // };
  return (
    <>
      {props?.sharedService?.length > 0 && (
        <>
          <Box
            className="sharedServices-4"
            style={{
              backgroundImage: `url(${Background4})`,
              // paddingTop : "140px",
              // // paddingBottom : "150px",
              // paddingRight : "100px",
              // paddingLeft : "100px"
            }}
          >
            <Grid
              className="sharedServices-5"
              container
              spacing={3}
              // style={{
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "space-around",
              //   flexDirection: "row",
              //   margin: "10px",
              //   textAlign: "center",
              //   border: "10px",
              //   paddingBottom : "150px"
              // }}
            >
              <>
                <Grid
                  item
                  xs="12"
                  className="navigators sharedServicesName"
                  style={{ paddingBottom: "0px" }}
                >
                  Shared Services Awards
                  {/* <div className="navigators sharedServicesName" style={{paddingBottom : "0px"}}>Shared Services Awards</div> */}
                </Grid>
                {props.sharedService.map((each) => {
                  const images = each.image_urls;

                  return (
                    <Grid
                      item
                      xs="auto"
                      className="sharedServices-6"
                      // style = {{ height : "175px",marginBottom:"12px"}}
                    >
                      <div className="sharedServices-7">
                        {images.map((eachImg) => {
                          return (
                            <OverlayTrigger
                              className="placementTrigger"
                              placement={
                                props.width > "800" ? "right" : "bottom"
                              }
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(
                                each.name,
                                each.award_name
                              )}
                              //style={{ backgroundColor: "#fff" }}
                            >
                              <img
                                src={require(`../${eachImg}`)}
                                className="c-class-margin c-class-image sharedServices-8"
                                alt=""
                                //style={{height : "100px" }}
                              />
                            </OverlayTrigger>
                          );
                        })}
                      </div>
                      <div>
                        <h1
                          className="sharedServices-9"
                          // style={{
                          //   fontFamily: "Rochester",
                          //   color: "white",
                          //   fontSize: "21px",
                          //   width : "220px",
                          //   paddingTop : "15px",
                          //   margin : "auto"
                          // }}
                        >
                          {each.award_name}
                        </h1>
                      </div>
                    </Grid>
                  );
                })}
              </>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default Shared_Services;
