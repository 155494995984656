import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./style.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Background2 from "../assets/backgroundImages/Background2.png";


const Project_Stratagic = (props) => {

  const renderTooltip = (name, award) => (
    <Tooltip id="button-tooltip" {...props}>
      <div
        className="container projectStrategic-1"
        // style={{
        //   display: "flex",
        //   justifyContent: "flex-start",
        //   flexDirection: "column",
        //   fontSize : "17px"
        // }}
      >
        {award}
        <div
          className="projectStrategic-2"
          // style={{
          //   fontFamily: "HelveticaNeue",
          //   fontSize: "14px",
          //   color: "#139ded",
          //   paddingTop: "15px",

          // }}
        >
          {name}
        </div>
        <hr
          className="projectStrategic-3"
          // style={{ color: "blue", width: "100%", marginTop: "5px ", marginBottom : "5px" }}
        />
      </div>
    </Tooltip>
  );
  
  // const sectionStyle = {
  //   height: "100vh",
  //   width: "100vw",
  //   display: "Flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   backgroundSize: "cover",
  //   // backgroundImage : `url(${Background1})`
  //   font: "white",
  //};
  return (
    <>
      {props?.projectAwardStarStratagicPerformers?.length > 0 && (
        <Box
          className="projectStrategic-4"
          style={{
            backgroundImage: `url(${Background2})`,
            // paddingTop : "150px",
            // paddingRight : "200px",
            // paddingLeft : "130px"
          }}
        >
          <Grid
            className="projectStrategic-5"
            container
            spacing={3}
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "space-around",
            //   flexDirection: "row",
            //   margin: "10px",
            //   textAlign: "center",
            //   border: "10px",
            //   paddingBottom : "150px"
            // }}
          >
            <Grid item xs="12" className="navigators projectStrategicName">
              Strategic Project Awards - Star Performers
              {/* <div className="navigators projectStrategicName">
                  Project stratagic Awards
                </div> */}
            </Grid>
            {props.projectAwardStarStratagicPerformers.map((each) => {
              
              const images = each.image_urls;
              
              return (
                <Grid
                  item
                  xs="auto projectStrategic-6"
                  //  style = {{ height : "175px",marginBottom:"12px"}}
                >
                  <div className="projectStrategic-7">
                    {images.map((eachImg) => {
                      
                      return (
                        <OverlayTrigger
                          className="placementTrigger"
                          placement={props.width > "800" ? "right" : "bottom"}
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip(each.name, each.award_name)}
                          //style={{ backgroundColor: "#fff" }}
                        >
                          <img
                            src={require(`../${eachImg}`)}
                            className="c-class-margin c-class-image projectStrategic-8"
                            alt=""
                            //style={{height : "100px" }}
                          />
                        </OverlayTrigger>
                      );
                    })}
                  </div>
                  <div>
                    <h1
                      className="projectStrategic-9"
                      // style={{
                      //   fontFamily: "Rochester",
                      //   color: "white",
                      //   fontSize: "21px",
                      //   width : "220px",
                      //   paddingTop : "15px",
                      //   margin : "auto"
                      // }}
                    >
                      {each.award_name}
                    </h1>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Project_Stratagic;
