import React from "react";
import Box from "@mui/material/Box";
import Background1 from "../assets/backgroundImages/Background1.jpg";
import Grid from "@mui/material/Grid";
import "./style.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const Project_Fixed = (props) => {
  const renderTooltip = (name, award) => (
    
    <Tooltip id="button-tooltip" {...props}>
      <div
        className="container projectFixed-1"
        // style={{
        //   display: "flex",
        //   justifyContent: "flex-start",
        //   flexDirection: "column",
        //   fontSize : "17px"
        // }}
      >
        {award}
        <div
          className="projectFixed-2"
          // style={{
          //   fontFamily: "HelveticaNeue",
          //   fontSize: "14px",
          //   color: "#139ded",
          //   paddingTop: "15px",
          // }}
        >
          {name}
        </div>
        <hr
          className="projectFixed-3"
          // style={{ color: "blue", width: "100%", marginTop: "5px ", marginBottom : "5px" }}
        />
      </div>
    </Tooltip>
  );
  
  // const sectionStyle = {
  //   height: "100vh",
  //   width: "100vw",
  //   display: "Flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   backgroundSize: "cover",
  //   // backgroundImage : `url(${Background1})`
  //   font: "white",
  // };
  return (
    <>
      {props?.projectAwardStarFixedPerformer?.length > 0 && (
        <Box
          className="projectFixed-4"
          style={{
            backgroundImage: `url(${Background1})`,
            // paddingTop : "150px",
            // paddingRight : "100px",
            // paddingLeft : "100px"
          }}
        >
          <Grid
            className="projectFixed-5"
            container
            spacing={3}
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "space-around",
            //   flexDirection: "row",
            //   margin: "10px",
            //   textAlign: "center",
            //   border: "10px",
            //   paddingBottom : "200px"
            // }}
          >
            <>
              <Grid item xs="12" className="navigators projectFixedName">
                 Fixed Bid Project Awards - Star Performers
                {/* <div className="navigators projectFixedName">
                  Project Award Fixed Performer
                </div> */}
              </Grid>
              {props.projectAwardStarFixedPerformer.map((each) => {
               
                const images = each.image_urls;
               
                return (
                  <Grid
                    item
                    xs="auto"
                    className="projectFixed-6"
                    // style = {{ height : "175px"}}
                  >
                    <div className="projectFixed-7">
                      {images.map((eachImg) => {
                       
                        return (
                          <OverlayTrigger
                            className="placementTrigger"
                            placement={props.width > "800" ? "right" : "bottom"}
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip(each.name, each.award_name)}
                            style={{ backgroundColor: "#fff" }}
                          >
                            <img
                              src={require(`../${eachImg}`)}
                              className="c-class-margin c-class-image projectFixed-8"
                              alt=""
                              // style={{height : "100px" }}
                            />
                          </OverlayTrigger>
                        );
                      })}
                    </div>
                    <div>
                      <h1
                        className="projectFixed-9"
                        // style={{
                        //   fontFamily: "Rochester",
                        //   color: "white",
                        //   fontSize: "21px",
                        //   width : "220px",
                        //   paddingTop : "15px",
                        //   margin : "auto"
                        // }}
                      >
                        {each.award_name}
                      </h1>
                    </div>
                  </Grid>
                );
              })}
            </>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Project_Fixed;
